import React, { useState } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Dialog } from '@reach/dialog';
import UpArrowSVG from '../../components/UpArrowSVG';
import Layout from '../../components/Layout';
import SEO from '../../components/SEO';
import ExteriorsImageGrid from '../../components/ExteriorsImageGrid';
import '@reach/dialog/styles.css';
import CloseSVG from '../../components/CloseSVG';

const Exteriors = () => {

    const [showDialog, setShowDialog] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');

    const open = imageData => {
        setSelectedImage(imageData);
        setShowDialog(true);
    };

    const close = () => setShowDialog(false);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <Layout>
            <SEO
                title="Exteriors"
                description="Take a look at our home exteriors. We only use the highest-quality building materials when constructing your dream home so they are built to last and beautiful."
            />
            <section className="main-exteriors" aria-label="Exterior Photos">
                <div className="main-exteriors__container">
                    <h2 className="main-exteriors__title">
                        Exteriors
                    </h2>
                    <ExteriorsImageGrid open={open} />
                    <button onClick={scrollToTop} className="main-exteriors__scroll">
                        <UpArrowSVG className="main-exteriors__svg" />
                        <span className="visually-hidden">Scroll To Top</span>
                    </button>
                </div>
            </section>
            <Dialog isOpen={showDialog} onDismiss={close}>
                <button className="dialog-close" onClick={close}>
                    <CloseSVG className="dialog-svg" />
                </button>
                <GatsbyImage image={selectedImage} className="dialog-image" alt="" />
            </Dialog>
        </Layout>
    );
};

export default Exteriors;
