import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import GridImage from '../components/GridImage';

const ExteriorsImageGrid = ({ open }) => {

    const data = useStaticQuery(graphql`
      query {
        propertyPhotos: allFile(
          filter: {relativeDirectory: {eq: "exteriors"}}
          sort: {fields: base, order: ASC}) 
          {
          edges {
            node {
              id
              base
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  blurredOptions: {width: 100}
                  quality: 70
                )
              }
            }
          }
        }
      }
    `);

    return (
        <div className="main-exteriors__grid">
        {
            data.propertyPhotos.edges.map(edge => {
              const image = getImage(edge.node);

              return (
                <GridImage directory="exteriors" key={edge.node.id} image={image} open={open}/>
              );
            })
        }
        </div>
    );
};

export default ExteriorsImageGrid;
